<template>
  <vx-card title="Promociones">
    <template slot="actions">
      <div>
        <vs-button
          v-if="$can('create', 'promotion')"
          size="small" color="success" type="border" icon-pack="feather" icon="icon-file-plus"
          @click="createEntry()"
        >
          Nueva Promoción
        </vs-button>
      </div>
    </template>

    <div class="vx-row">
      <div class="vx-col md:w-1/4 w-full mt-10">
        <div class="m-3 mt-10">
          <filter-groups :model="queryStrings" :filter-groups="filterGroups" @update="getEntries"/>
          <vs-divider/>
          <div>
            <ul class="centerx">
              <div class="mt-4"><b>Papelera de reciclaje</b></div>
              <li>
                <vs-checkbox v-model="recycleBin" class="mt-4" @change="getEntries">Si</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="vx-col md:w-3/4 w-full">
        <vs-table max-items="10" pagination search stripe :data="entries" :hoverFlat="true">
          <template slot="thead">
            <vs-th sort-key="featured"/>
            <vs-th sort-key="institution">Institución</vs-th>
            <vs-th sort-key="name">Nombre</vs-th>
            <vs-th sort-key="publication_status">Estado</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="record" :key="index" v-for="(record, index) in data">
              <vs-td>
                <span>
                  <vs-icon
                    :icon="record.featured === true ? 'star': 'star_border'"
                    :color="record.featured === true ? 'warning': ''"
                    size="small"
                  />
                </span>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.institution.name }}</p>
              </vs-td>
              <vs-td>
                <p class="font-medium">{{ record.name }}</p>
              </vs-td>
              <vs-td>
                <vs-chip :color="record.publication_status | publication_status_color">
                  {{ record.publication_status | publication_status_label }}
                </vs-chip>
              </vs-td>
              <vs-td>
                <div class="flex" v-if="!recycleBin">
                  <vx-tooltip text="Editar">
                    <vs-button
                      :disabled="!($can('retrieve', 'promotion') || $can('update', 'promotion'))"
                      radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                      @click="retrieveEntry(record.id)" @click.stop
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Inbox">
                    <vs-button
                      radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-inbox"
                      @click="retrieveForms(record.id)" @click.stop
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Publicar">
                    <vs-button
                      radius size="large" :color="record.in_production ? 'dark' : 'success'" type="flat" icon-pack="feather" icon="icon-send"
                      :disabled="record.in_production"
                      @click="confirmCommit(record)" @click.stop
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Eliminar">
                    <vs-button
                      :disabled="!$can('delete', 'promotion')"
                      radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                      @click="confirmDelete(record)" @click.stop
                    />
                  </vx-tooltip>
                </div>
                <div class="flex" v-else>
                  <vx-tooltip text="Restaurar">
                    <vs-button
                        :disabled="!($can('create', 'promotion') || $can('update', 'promotion'))"
                        radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-refresh-cw"
                        @click="confirmRestore(record)" @click.stop
                    />
                  </vx-tooltip>
                </div>
              </vs-td>

              <template slot="expand">
                <vs-row>
                  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                    <vs-list class="mr-1 ml-2">
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-menu"
                        title="Segmento"
                        :subtitle="record.segment.name"
                      />
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-grid"
                        title="Categoría"
                        :subtitle="record.category.name"
                      />
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-shopping-bag"
                        title="Producto"
                        :subtitle="record.product.name"
                      />
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-link"
                        title="URL Portal Privado"
                        :subtitle="record | entry_url(moduleSlug, 'staging')"
                      >
                        <div class="flex">
                          <vx-tooltip text="Ir">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-external-link"
                              @click="goToEntryPage(record, 'staging')"
                            />
                          </vx-tooltip>
                          <vx-tooltip text="Copiar">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-copy"
                              @click="copyEntryURLToClipboard(record, 'staging')"
                            />
                          </vx-tooltip>
                        </div>
                      </vs-list-item>
                      <vs-list-item
                        size="large" icon-pack="feather" icon="icon-link"
                        title="URL Portal Público"
                        :subtitle="record | entry_url(moduleSlug, 'public')"
                      >
                        <div class="flex">
                          <vx-tooltip text="Ir">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-external-link"
                              @click="goToEntryPage(record, 'public')"
                            />
                          </vx-tooltip>
                          <vx-tooltip text="Copiar">
                            <vs-button
                              radius size="large" color="dark" type="flat" icon-pack="feather" icon="icon-copy"
                              @click="copyEntryURLToClipboard(record, 'public')"
                            />
                          </vx-tooltip>
                        </div>
                      </vs-list-item>
                    </vs-list>
                  </vs-col>
                </vs-row>
              </template>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
  import Vue from 'vue';
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import FilterGroups from '@/components/filter-groups/FilterGroups';
  import * as notificationMixins from "../../utils/notificationMixins";

  let notifier = new Notifier();
  const ENTRIES_CLIENT = new EntriesClient("promotions");
  const INSTITUTIONS_CLIENT = new CoreClient("institutions");

  export default {
    data() {
      return {
        moduleSlug: "promociones",
        entries: [],
        institutions: [],
        queryStrings: {},
        filterGroups: [
          {
            name: "institution_id",
            label: "Instituciones",
            filters: []
          },
          {
            name: "publication_status",
            label: "Estado",
            filters: [
              {value: "published", label: "Publicado"},
              {value: "unpublished", label: "No publicado"},
              {value: "expired", label: "Vencido"}
            ],
          },
          {
            name: "featured",
            label: "Destacado",
            filters: [
              {value: true, label: "Si"},
              {value: false, label: "No"}
            ]
          }
        ],
        recycleBin: false,
      };
    },

    mounted() {
      this.$vs.loading();
      this.getEntries()
        .then(() => {
          this.getInstitutions();
        })
        .then(() => {
          this.$vs.loading.close();
        });
    },

    methods: {
      async getEntries(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await ENTRIES_CLIENT.all({fromRecycleBin: this.recycleBin, queryStrings: this.queryStrings})
          .then(response => {
            this.entries = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getInstitutions() {
        this.$vs.loading();
        await INSTITUTIONS_CLIENT.all()
          .then(response => {
            response.data.forEach(element => {
              this.filterGroups[0].filters.push({
                value: element.id,
                label: element.name
              });
            });
            this.$vs.loading.close();
          })
          .catch(error => {
            this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async deleteEntry(entryId) {
        if (entryId !== null && entryId !== undefined && entryId !== "") {
          this.$vs.loading();
          await ENTRIES_CLIENT.delete({pathParameters: {entryId: entryId}})
            .then(response => {
              notifier.notification("deleted");
              this.getEntries();
            })
            .catch(error => {
              notifier.alertMessage("error");
            });
          this.$vs.loading.close();
        }
      },

      confirmDelete(entry) {
        notifier.deleteMessage().then(result => {
          if (result.value) {
            this.deleteEntry(entry.id);
          }
        });
      },

      confirmCommit(entry) {
        notificationMixins.CommitEntryNotification.fire()
          .then(result => {
            if (result.value) {
              if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                this.$vs.loading();
                ENTRIES_CLIENT.commit({pathParameters: {entryId: entry.id}})
                  .then(response => {
                    notifier.notification("committed");
                    this.getEntries();
                  })
                  .catch(error => {
                    notifier.alertMessage("error");
                  });
                this.$vs.loading.close();
              }
            }
          });
      },

      confirmRestore(entry) {
        notificationMixins.RestoreEntryNotification.fire()
            .then(result => {
              if (result.value) {
                if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                  this.$vs.loading();
                  ENTRIES_CLIENT.restore({pathParameters: {entryId: entry.id}})
                      .then(response => {
                        notifier.notification("restored");
                        this.getEntries();
                      })
                      .catch(error => {
                        notifier.alertMessage("error");
                      });
                  this.$vs.loading.close();
                }
              }
            });
      },

      createEntry() {
        this.$router.push({name: "create-promotion"});
      },

      retrieveEntry(entryId) {
        this.$router.push({name: "promotion", params: {id: entryId}});
      },

      retrieveForms(entryId) {
        this.$router.push({name: "promotions-inbox", params: {entryId: entryId}});
      },

      goToEntryPage(entry, stage) {
        window.open(Vue.filter("entry_url")(entry, this.moduleSlug, stage));
      },

      copyEntryURLToClipboard(entry, stage) {
        navigator.clipboard.writeText(Vue.filter("entry_url")(entry, this.moduleSlug, stage));
      },
    },

    components: {
      'filter-groups': FilterGroups
    }
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
